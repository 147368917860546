import * as React from 'react'
import { PageContainer, PageContentContainer } from './PageContentContainer'
import styled from '@emotion/styled'
import { PageTitle } from './PageTitle'
import { getCssProp, Styles } from '../../utilities/getCssProp'
import { QueryStatusIndicatorProps, QueryStatusIndicator } from './QueryStatusIndicator'
import { PageWithSidebarContainer } from '../components/PageWithSidebar/common'
import Head from 'next/head'

export const standardPageHeaderId = 'standard-page-header'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

type ConditionalSidebarWrapperProps = React.PropsWithChildren<{ sidebar?: React.ReactNode }>

function ConditionalSidebarWrapper({ children, sidebar }: ConditionalSidebarWrapperProps) {
  const Wrapper = sidebar ? PageWithSidebarContainer : React.Fragment
  return (
    <Wrapper>
      {sidebar || null}
      {children}
    </Wrapper>
  )
}
export const standardPageContainerId = 'standard-page-container'

type HeadTagMetaData = { title?: string }

type Component = () => JSX.Element

const HeaderRightContentContainer = styled.div`
  margin-bottom: 20px;
  min-width: fit-content;
`

export type StandardPageProps = React.PropsWithChildren<
  {
    isCentered?: boolean
    headerTitle?: React.ReactNode
    sidebar?: React.ReactNode
    subheader?: React.ReactNode
    headerRightContent?: React.ReactNode
    styles?: {
      page?: Styles
      pageContentContainer?: Styles
      headerTitle?: Styles
      header?: Styles
      headerRightContent?: Styles
    }
    id?: string
    htmlHeadMetaData?: HeadTagMetaData | Component | React.ReactNode
  } & QueryStatusIndicatorProps
>
export function StandardPage({
  headerTitle,
  sidebar,
  subheader,
  headerRightContent,
  styles,
  children,
  loading,
  error,
  noResultDisplay,
  id,
  omitNoResultsDefaultStyling,
  isCentered,
  htmlHeadMetaData: HtmlHeadMetaData,
}: StandardPageProps) {
  const pageCss = getCssProp(styles?.page)
  const pageContentContainerCss = getCssProp(styles?.pageContentContainer)
  const headerCss = getCssProp(styles?.header)
  const headerTitleCss = getCssProp(styles?.headerTitle)
  const headerRightContentCss = getCssProp(styles?.headerRightContent)

  const htmlHeadJsx =
    typeof HtmlHeadMetaData === 'function' ? (
      <HtmlHeadMetaData />
    ) : React.isValidElement(HtmlHeadMetaData) ? (
      HtmlHeadMetaData
    ) : HtmlHeadMetaData && typeof HtmlHeadMetaData === 'object' && 'title' in HtmlHeadMetaData ? (
      <Head>
        <title>{HtmlHeadMetaData.title}</title>
      </Head>
    ) : null
  return (
    <>
      {htmlHeadJsx}
      <ConditionalSidebarWrapper sidebar={sidebar}>
        <PageContainer css={pageCss} id={id ?? standardPageContainerId} $isCentered={isCentered ?? true}>
          <PageContentContainer $isCentered={isCentered ?? true} css={pageContentContainerCss}>
            <Header css={headerCss} id={standardPageHeaderId}>
              {/* need span so that if there is no header title but we have header right content the flex still works to push it to the end */}
              {headerTitle ? <PageTitle css={headerTitleCss}>{headerTitle}</PageTitle> : <span />}
              {headerRightContent && (
                <HeaderRightContentContainer css={headerRightContentCss}>
                  {headerRightContent}
                </HeaderRightContentContainer>
              )}
            </Header>
            {subheader && <>{subheader}</>}
            {loading || error || noResultDisplay ? (
              <QueryStatusIndicator
                loading={loading}
                error={error}
                noResultDisplay={!loading && !error && noResultDisplay}
                omitNoResultsDefaultStyling={omitNoResultsDefaultStyling}
              />
            ) : (
              children
            )}
          </PageContentContainer>
        </PageContainer>
      </ConditionalSidebarWrapper>
    </>
  )
}
