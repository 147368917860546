import styled from '@emotion/styled'
import { theme } from '../../styles/theme'

export const PageTitle = styled.h1`
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  width: fill-available;
  font-family: ${theme.fonts.ogg} !important;
`
