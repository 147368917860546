import * as React from 'react'
import { getPublicEnv } from '../../../../../../env/getEnvs'
import { useHost } from './useHost'

const {
  NEXT_PUBLIC_CANADIAN_FRANCHISE_BOOKING_DOMAIN: canadianFranchiseBookingDomain,
  NEXT_PUBLIC_CANADIAN_APP_DOMAIN: canadianAppDomain,
} = getPublicEnv()

export const useIsCanada = () => {
  const host = useHost()
  const isCanada = React.useMemo(
    () => [canadianAppDomain, canadianFranchiseBookingDomain].some((x) => host?.includes(x)),
    [host]
  )
  return isCanada
}
