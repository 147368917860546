'use client'
import * as React from 'react'
import styled from '@emotion/styled'
import {
  UseCarouselOptions,
  UseCarouselReturn,
  useCarousel,
} from '../../../common/page-components/Carousel/useCarousel'
import { Styles, getCssProp } from '../../../utilities/getCssProp'
import { Carousel, CarouselProps, emblaDivContainerId } from '../../../common/page-components/Carousel/Carousel'
import { UnstyledButton } from '../../../common/style/UnstyledButton'
import { transientOptions } from '../../../common/style/transientOptions'
import { NavigationArrow } from '../../../assets/components/NavigationArrow'
import { theme } from '../../../styles/theme'
import { getCssSizeString } from '../../../utilities/getCssSizeString'
import { isCssSize } from '../../../typescript/guards/isCssSize'

type Dual<T> = { right?: T; left?: T }

type LeftRightOrDual<T = boolean> = T | Dual<T>

type OverflowDistance = number | string | undefined

type Props = React.PropsWithChildren<{
  overflowDistance?: LeftRightOrDual<OverflowDistance>
  hasGradients?: LeftRightOrDual
  gradientColor?: string
  gradientStyles?: Styles
  styles?: Styles
  dontFadeOutGradientAtMargin?: boolean
  absoluteElements?: React.ReactNode
  useCarouselOptions?: Partial<UseCarouselOptions>
  carouselProps?: Partial<CarouselProps>
  hasArrows?: LeftRightOrDual
  sidePadding?: LeftRightOrDual<OverflowDistance>
  carouselReturnRef?: React.MutableRefObject<UseCarouselReturn | null>
}>

type ContainerProps = {
  $leftOverflow?: string | number
  $rightOverflow?: string | number
  $leftPadding?: string | number
  $rightPadding?: string | number
}
const Container = styled('div', transientOptions)<ContainerProps>`
  position: relative;
  width: calc(
    100% + ${(p) => `calc(${getDefaultedCssString(p.$leftOverflow)} + ${getDefaultedCssString(p.$rightOverflow)})`}
  );
  margin-right: ${(p) => `calc(${getDefaultedCssString(p.$leftOverflow)} * -1)`};
  margin-left: ${(p) => `calc(${getDefaultedCssString(p.$rightOverflow)} * -1)`};

  #${emblaDivContainerId} {
    padding-left: ${(p) => getDefaultedCssString(p.$leftPadding)};
    padding-right: ${(p) => getDefaultedCssString(p.$rightPadding)};
  }
`

const getDefaultedCssString = (overflow: OverflowDistance) => (isCssSize(overflow) ? getCssSizeString(overflow) : '0px')

type GradientProps = {
  $isVisible: boolean
  $gradientColor: string
  $overflow: OverflowDistance
}

const EndGradient = styled('div', transientOptions)<GradientProps>`
  background: linear-gradient(
    90deg,
    rgba(249, 249, 251, 0) 0%,
    ${(p) => p.$gradientColor} calc(100% - ${(p) => getDefaultedCssString(p.$overflow)})
  );
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: calc(${(p) => getDefaultedCssString(p.$overflow)} + 42px);
  pointer-events: none;
  opacity: ${(p) => (p.$isVisible ? 1 : 0)};
  transition: opacity 0.3s;
`

const BeginningGradient = styled(EndGradient)`
  background: linear-gradient(
    270deg,
    rgba(249, 249, 251, 0) 0%,
    ${(p) => p.$gradientColor} calc(100% - ${(p) => getDefaultedCssString(p.$overflow)})
  );
  right: unset;
  left: 0;
`

const ArrowButton = styled(UnstyledButton, transientOptions)<{ $show: boolean }>`
  border-radius: 30px;
  width: 40px;
  height: 40px;
  background: ${theme.colors.grayscalewhite};
  box-shadow: 0px 2px 10px 0px rgba(1, 20, 98, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(${(p) => (p.$show ? 1 : 0)});
  transition: transform 0.3s;
`

const ArrowRightContainer = styled('div', transientOptions)<{ $overflow: OverflowDistance }>`
  position: absolute;
  right: ${(p) => getDefaultedCssString(p.$overflow)};
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`

const ArrowLeftContainer = styled(ArrowRightContainer)`
  left: ${(p) => getDefaultedCssString(p.$overflow)};
  right: unset;
`

function isDual<T>(input: LeftRightOrDual<T>): input is Dual<T> {
  return !!input && typeof input === 'object' && ('left' in input || 'right' in input)
}

function getLeftRightValue<T>(input: LeftRightOrDual<T>) {
  const left = isDual(input) ? input.left : input
  const right = isDual(input) ? input.right : input
  return { left, right }
}

export function HorizontalCarousel({
  children,
  overflowDistance,
  hasGradients,
  hasArrows,
  styles,
  gradientColor = theme.colors.grayscalewhite,
  dontFadeOutGradientAtMargin,
  absoluteElements,
  gradientStyles,
  useCarouselOptions,
  carouselProps,
  sidePadding,
  carouselReturnRef,
}: Props) {
  const props = useCarousel({ containScroll: 'keepSnaps', ...useCarouselOptions })

  const leftRightOverflowDistance = getLeftRightValue(overflowDistance)

  if (carouselReturnRef) carouselReturnRef.current = props
  const cssProp = getCssProp(styles)
  const gradientCssProp = getCssProp(gradientStyles)

  const leftRightHasGradients = getLeftRightValue(hasGradients)
  const leftRightHasArrows = getLeftRightValue(hasArrows)
  const leftRightPadding = getLeftRightValue(sidePadding)

  const isCarousel = props.totalSlides > 1
  const isAtEnd = !isCarousel || props.progressOfTotal >= 1
  const isAtBeginning = !isCarousel || props.progressOfTotal <= 0

  return (
    <Container
      $leftOverflow={leftRightOverflowDistance.left}
      $rightOverflow={leftRightOverflowDistance.right}
      css={cssProp}
      $leftPadding={leftRightPadding.left}
      $rightPadding={leftRightPadding.right}
    >
      <Carousel {...props} {...carouselProps}>
        {children}
      </Carousel>

      {leftRightHasGradients.right && (
        <EndGradient
          $overflow={leftRightOverflowDistance.right}
          $gradientColor={gradientColor}
          $isVisible={dontFadeOutGradientAtMargin || !isAtEnd}
          css={gradientCssProp}
        />
      )}
      {leftRightHasGradients.left && (
        <BeginningGradient
          $overflow={leftRightOverflowDistance.left}
          $gradientColor={gradientColor}
          $isVisible={dontFadeOutGradientAtMargin || !isAtBeginning}
          css={gradientCssProp}
        />
      )}
      {leftRightHasArrows.right && (
        <ArrowRightContainer $overflow={leftRightOverflowDistance.right}>
          <ArrowButton
            $show={!isAtEnd}
            onClick={(e) => {
              e.stopPropagation()
              props.emblaApi?.scrollTo(props.selectedIndex + (props.emblaApi.slidesInView().length ?? 1))
            }}
          >
            <NavigationArrow orientation="right" size={30} />
          </ArrowButton>
        </ArrowRightContainer>
      )}
      {leftRightHasArrows.left && (
        <ArrowLeftContainer $overflow={leftRightOverflowDistance.left}>
          <ArrowButton
            $show={!isAtBeginning}
            onClick={(e) => {
              e.stopPropagation()
              props.emblaApi?.scrollTo(props.selectedIndex - (props.emblaApi.slidesInView().length ?? 1))
            }}
          >
            <NavigationArrow orientation="left" size={30} />
          </ArrowButton>
        </ArrowLeftContainer>
      )}
      {absoluteElements ?? null}
    </Container>
  )
}
