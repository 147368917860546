import { isRealNumber } from '../../typescript/guards/isRealNumber'

export const ellipsisCss = `
white-space: nowrap;
overflow: hidden;
word-break: break-word;
text-overflow: ellipsis;
`

/**
 * css helper to limit text to X wrapped lines before hiding any continued text and showing an ellipsis on the final line
 */
export const ellipsisLineClampCss = (lineCount: number = 1) => `
overflow: hidden;
text-overflow: ellipsis;
word-break: break-word;
display: -webkit-box;
-webkit-line-clamp: ${isRealNumber(lineCount) ? lineCount : 1};
-webkit-box-orient: vertical;
`
