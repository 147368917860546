import * as React from 'react'
import styled from '@emotion/styled'
import type { UseCarouselReturn } from './useCarousel'
import { getCssProp, Styles } from '../../../utilities/getCssProp'
import dynamic from 'next/dynamic'

const LazyScrollBar = dynamic(() => import('./Scrollbar').then((x) => x.Scrollbar))

// TODO feel like i have display:flex, flex:1, and flex-direction column littering a chain of like 50 parent > child divs to make
// this content fill all available space vertically. there has to be a better way to get this to do what i want. very
// finicky css for this to work though
const Embla = styled.div`
  width: 100%;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
`
const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const SliderItemsContainer = styled.div<{ totalSlides: number }>`
  display: flex;
  flex: 1;
  cursor: ${(p) => (p.totalSlides > 1 ? 'grab' : 'default')};

  :active {
    cursor: ${(p) => (p.totalSlides > 1 ? 'grabbing' : 'default')};
  }
`

const ScrollbarFiller = styled.div`
  height: 14px;
`

type CarouselUseCarouselProps = Omit<UseCarouselReturn, 'Carousel'>
export const emblaDivContainerId = 'embla-div-container'
export type CarouselProps = React.PropsWithChildren<
  CarouselUseCarouselProps & {
    showControls?: boolean
    styleProps?: { sliderItemsContainer?: Styles; container?: Styles; embla?: Styles; scrollbar?: Styles }
  }
>

export function Carousel({
  // user provided
  children,
  showControls,
  styleProps,
  // provided automatically by useCarousel
  emblaRef,
  totalSlides,
  selectedIndex,
  scrollTo,
}: CarouselProps) {
  const containerCssProp = getCssProp(styleProps?.container)
  const sliderItemsContainerCssProp = getCssProp(styleProps?.sliderItemsContainer)
  const scrollbarCssProp = getCssProp(styleProps?.scrollbar)
  const emblaCssProp = getCssProp(styleProps?.embla)
  return (
    <Container css={containerCssProp}>
      <Embla ref={emblaRef} css={emblaCssProp} id={emblaDivContainerId}>
        <SliderItemsContainer totalSlides={totalSlides} css={sliderItemsContainerCssProp}>
          {children}
        </SliderItemsContainer>
      </Embla>
      {showControls && (
        <>
          {totalSlides > 1 ? (
            <LazyScrollBar
              styles={scrollbarCssProp}
              selectedIndex={selectedIndex}
              totalSlides={totalSlides}
              scrollTo={scrollTo}
            />
          ) : (
            <ScrollbarFiller />
          )}
        </>
      )}
    </Container>
  )
}
