import * as React from 'react'
import useEmblaCarousel, { EmblaOptionsType, EmblaPluginType } from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import throttle from 'lodash/throttle'

export type UseCarouselReturn = ReturnType<typeof useCarousel>

type Options = EmblaOptionsType & { reinitDeps?: any[] }

export type UseCarouselOptions = Options | undefined
export const useCarousel = (options: Options = {}, plugins?: EmblaPluginType[]) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: false, skipSnaps: true, ...options },
    (plugins ?? []).concat(options.draggable !== false ? WheelGesturesPlugin() : [])
  )

  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [scrollSnaps, setScrollSnaps] = React.useState([] as number[])
  const [progressOfTotal, setProgressOfTotal] = React.useState(0)

  React.useEffect(() => {
    if (!emblaApi) return

    const onSelect = () => {
      if (!emblaApi) return
      setSelectedIndex(emblaApi.selectedScrollSnap())
    }
    const onScroll = throttle(() => {
      setProgressOfTotal(emblaApi.scrollProgress())
    }, 200)

    onSelect()
    setScrollSnaps(emblaApi.scrollSnapList())
    emblaApi.on('select', onSelect)
    emblaApi.on('scroll', onScroll)
  }, [emblaApi, setScrollSnaps, ...(options.reinitDeps ?? [])]) // eslint-disable-line react-hooks/exhaustive-deps

  const scrollTo = React.useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi])

  const totalSlides = scrollSnaps.length

  const progressOfSlide = (totalSlides - 1) * progressOfTotal

  return {
    totalSlides,
    emblaRef,
    emblaApi,
    selectedIndex,
    scrollTo,
    progressOfTotal,
    progressOfSlide,
  }
}
