import styled from '@emotion/styled'
import { notebookUpMq, tabletDownMq, tabletToNotebookMq } from '../../styles/mediaQueries'
import { theme } from '../../styles/theme'

const { paddingBottom, paddingTop, paddingRight, paddingLeft } = theme.spacing

export const PageContainer = styled.div<{ $isCentered?: boolean }>`
  padding: ${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft};
  background-color: ${theme.colors.grayscale000};
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: ${(p) => (p.$isCentered ? 'center' : 'initial')};
  overflow: hidden;
`

export const PageContentContainer = styled.div<{ $isCentered?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: ${theme.spacing.maxContentWidth};
  align-self: ${(p) => (p.$isCentered ? 'center' : 'initial')};
`

export const overridePageHorizontalPadding = `
  margin-left: calc(${paddingLeft} * -1);
  margin-right: calc(${paddingRight} * -1);
  width: calc(100% + ${paddingLeft} + ${paddingRight});
`

export const overridePageTopPadding = `
  margin-top: calc(${paddingTop} * -1);
`

/**
 * Make a div full bleed and override the default page padding
 * optionally takes booleans for desktop, tablet, phone
 * if none are passed, apply full bleed width to all sizes.
 * if any are passed, only apply it to those which are passed as true
 */
export const OverridePageHorizontalPadding = styled.div<{
  desktop?: boolean
  phone?: boolean
  tablet?: boolean
}>`
  ${({ desktop, phone, tablet }) => {
    if ([desktop, phone, tablet].every((x) => x === undefined)) {
      return overridePageHorizontalPadding
    }
    let res = ''

    if (desktop) {
      res += `${notebookUpMq} {
        ${overridePageHorizontalPadding}
      }`
    }
    if (tablet) {
      res += `${tabletToNotebookMq} {
        ${overridePageHorizontalPadding}
      }`
    }
    if (phone) {
      res += `${tabletDownMq} {
        ${overridePageHorizontalPadding}
      }`
    }
    return res
  }}
`
