'use client'
import * as React from 'react'
import styled from '@emotion/styled'
import { LEGACY_NextImage } from '../../../common/functionality/LEGACY_NextImage'
import { theme } from '../../../styles/theme'
import { useDevice } from '../../../hooks/useDevice'
import { notebookUpMq, phoneDownMq, tabletDownMq } from '../../../styles/mediaQueries'
import { LazyMarkdownRenderer } from '../../../common/components/MarkdownRenderer/LazyMarkdownRenderer'
import { getShopPageHeroImage } from '../utils/getShopPageHeroImage'

const Container = styled.div`
  width: 100%;
  min-height: 134px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 48px;
  background: ${theme.colors.cultured};
  mix-blend-mode: multiply;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 24px;

  img {
    object-position: right;
    object-fit: contain;

    ${notebookUpMq} {
      object-fit: cover;
    }
    ${phoneDownMq} {
      object-fit: cover;
    }
  }

  ${tabletDownMq} {
    padding: 24px 16px;
    margin-bottom: 8px;
  }
`

const Title = styled.h1`
  color: ${theme.colors.primary700};
  font-size: 42px;
  font-weight: 300;

  ${tabletDownMq} {
    font-size: 30px;
  }
`

const Subtitle = styled.h2`
  color: ${theme.colors.grayscale700};
  font-size: 13px;
  font-weight: 500;
  max-width: 80%;

  ${tabletDownMq} {
    max-width: 50%;
  }
`

type Props = { title: React.ReactNode; subtitle?: string | null }

export function ShopHeroBanner({ title, subtitle }: Props) {
  const { isNotebookDown } = useDevice()
  return (
    <Container>
      <div style={{ zIndex: 1, width: '100%' }}>
        <Title>{title}</Title>
        {!!subtitle && (
          <Subtitle>
            <LazyMarkdownRenderer markdown={subtitle} />
          </Subtitle>
        )}
      </div>
      <LEGACY_NextImage src={getShopPageHeroImage(isNotebookDown ? 'mobile' : 'desktop')} layout="fill" priority />
    </Container>
  )
}
