import styled from '@emotion/styled'
import * as React from 'react'
import Link, { LinkProps } from 'next/link'
import { ClickEvent } from '../../typescript/utility'
import { theme } from '../../styles/theme'
import { Styles, getCssProp } from '../../utilities/getCssProp'
import { css } from '@emotion/react'
import { unstyledLinkStyles } from '../style/unstyledLinkStyles'

export const newRealizeLinkStyles = `
  color: ${theme.colors.primary700};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: underline;
`

export type NextLinkProps = React.PropsWithChildren<
  LinkProps & {
    style?: React.CSSProperties
    styles?: Styles
    onClick?: (e: ClickEvent<HTMLAnchorElement>) => void
    className?: string
    target?: React.HTMLAttributeAnchorTarget
  } & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>
>

function NextLinkBase({ styles, ...rest }: NextLinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) {
  const cssProp = getCssProp(styles)

  return (
    <Link
      ref={ref}
      css={css`
        ${unstyledLinkStyles}
        ${cssProp}
      `}
      {...rest}
    />
  )
}

export const NextLink = React.forwardRef(NextLinkBase)

type DisabledProps = React.PropsWithChildren<{ disabled: true; styles?: Styles; children?: React.ReactNode }>

type RegularProps = NextLinkProps & { disabled?: false }

const DisabledWrapper = styled.span`
  cursor: not-allowed;
`

type AnchorRef = React.ForwardedRef<HTMLAnchorElement>
type SpanRef = React.ForwardedRef<HTMLSpanElement>

// !!Type inference failing when actually using the component. Says href is required in DisableableLinkProps which is
// patently false

export type DisableableLinkProps = DisabledProps | RegularProps
function DisableableLinkBase(disabledProps: DisabledProps, ref: SpanRef): JSX.Element
function DisableableLinkBase(regularProps: RegularProps, ref: AnchorRef): JSX.Element
function DisableableLinkBase(props: DisableableLinkProps, ref: AnchorRef | SpanRef) {
  const { disabled, ...rest } = props
  switch (disabled) {
    case true: {
      const cssProp = getCssProp(props.styles)
      return (
        <DisabledWrapper css={cssProp} ref={ref}>
          {props.children}
        </DisabledWrapper>
      )
    }
    default: {
      // can't seem to get the type inference working here
      return (
        <NextLink {...props} ref={ref as unknown as React.ForwardedRef<HTMLAnchorElement>}>
          {rest.children}
        </NextLink>
      )
    }
  }
}

export const DisableableLink = React.forwardRef(DisableableLinkBase)
