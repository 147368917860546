import * as React from 'react'
import { useCookies } from '../../../hooks/useCookies'
import { useAccount } from '../../auth/hooks/useAccount'
import { userHasNonFreeMembershipCookieName } from '../../../common/constants/cookies'
import { addMonths } from 'date-fns'
import { userHasNonFreeMembership } from '../../../utilities/userHasNonFreeMembership'

export function useUserHasNonFreeMembership() {
  const { user, isLoading } = useAccount()
  const hasNonFreeMembership = userHasNonFreeMembership(user)
  const { set, remove, cookies } = useCookies()

  const hasNonFreeMembershipCookie = cookies[userHasNonFreeMembershipCookieName] === 'true'
  React.useEffect(() => {
    if (isLoading) return
    if (hasNonFreeMembership) {
      // assume they will be a member for the next month, and whenever that isn't the case,we'll update the cookie
      set(userHasNonFreeMembershipCookieName, 'true', { expires: addMonths(new Date(), 1) })
    } else {
      remove(userHasNonFreeMembershipCookieName)
    }
  }, [hasNonFreeMembership, isLoading, set, remove])

  return isLoading ? hasNonFreeMembershipCookie : hasNonFreeMembership
}
