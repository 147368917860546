'use client'
import { createContext, useContext } from 'react'

const Context = createContext<string | undefined>(undefined)

export const useHost = () => {
  const host = useContext(Context)
  if (host === undefined) throw new Error(`'useHost' must be used within a HostProvider`)

  return host
}

export function HostProvider({ children, host }: React.PropsWithChildren<{ host: string }>) {
  return <Context.Provider value={host}>{children}</Context.Provider>
}
