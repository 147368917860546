import React from 'react'

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' && process.env.NODE_ENV !== 'test' ? React.useLayoutEffect : React.useEffect

export const useIsomorphicIsMounted = () => {
  const [isMounted, setMountedState] = React.useState(false)

  useIsomorphicLayoutEffect(() => {
    setMountedState(true)
  }, [])
  return isMounted
}

type TProps = { clientComponent: React.ReactNode; serverComponent: React.ReactNode }
/**
 * In cases where a component will immediately differ when rendered on the client vs the server, use this component to
 * avoid mismatches in the dom which react will not reconcile/rerender (this leads to the component tree showing the
 * correct data, but the client specific dom never being inserted after hydration instead preserving the initial server html).
 * To prevent this, set up two separate components (or the same component but with different props) which are specific
 * to the client and server. In this way, you can still get SSR for the component and have client specific logic that
 * runs immediately to update the component when the client information is available.
 */
export function EnvironmentSpecificSsrComponent({ clientComponent = null, serverComponent = null }: TProps) {
  const isMounted = useIsomorphicIsMounted()

  return isMounted ? clientComponent : serverComponent
}
