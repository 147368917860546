import { isRealNumber } from './isRealNumber'

export function hasKey<O>(obj: O, key: any): key is keyof O {
  return !!(
    (typeof key === 'string' || isRealNumber(key)) &&
    obj &&
    (typeof obj === 'object' || typeof obj === 'function') &&
    key in obj
  )
}
