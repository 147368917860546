import * as React from 'react'
import { Display } from '../../../../common/style/Display'
import { enum_stripe_product_type_enum } from '../../../../generated/graphql'
import { useShopPageSections } from '../../../shop/pages/ShopPage'
import { DesktopCarousel } from './DesktopCarousel'
import { MobileCarousel } from './MobileCarousel'

type HomePageCarouselProps = { loading?: boolean }

export function HomePageCarousel({ loading }: HomePageCarouselProps) {
  const { sections } = useShopPageSections()

  const products = React.useMemo(
    () =>
      sections.flatMap((section) =>
        section.products.filter((product) => product.product_type === enum_stripe_product_type_enum.SUPPLEMENT)
      ),
    [sections]
  )

  return (
    <>
      <Display isTabletUp>
        <DesktopCarousel products={products} loading={loading} />
      </Display>
      <Display isTabletDown>
        <MobileCarousel products={products} loading={loading} />
      </Display>
    </>
  )
}
