import styled from '@emotion/styled'
import { notebookDownMq } from '../../../styles/mediaQueries'

export const PageWithSidebarContainer = styled.div`
  display: flex;
  margin-left: 250px; /* width of sidebar */

  ${notebookDownMq} {
    margin-left: 0;
  }
`

export const PageWithSidebarContent = styled.div`
  width: 100%;
`
