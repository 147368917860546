import styled from '@emotion/styled'
import { transientOptions } from '../../style/transientOptions'

export const EmblaCarousel = styled.div`
  width: 100%;
  overflow: hidden;
`
export const EmblaContainer = styled('div', transientOptions)<{ $totalSlides: number; $draggable?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.$totalSlides > 1 && p.$draggable !== false ? 'grab' : 'default')};

  :active {
    cursor: ${(p) => (p.$totalSlides > 1 && p.$draggable !== false ? 'grabbing' : 'default')};
  }
`
