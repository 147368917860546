import Image, { ImageLoaderProps, ImageProps } from 'next/legacy/image'
import * as React from 'react'
import { isBuilt } from '../../utilities/isBuilt'
import { imageDomains } from '../constants/imageDomains.mjs'
import dynamic from 'next/dynamic'

const LazyImageFallback = dynamic(() => import('./ImageFallback').then((x) => x.ImageFallback))
const wildcardLoader = ({ src }: ImageLoaderProps) => {
  if (!isBuilt) console.error('!! Unknown image domain for next/image. Image is not optimized. src: ', src)
  return src
}

type Props = ImageProps & { isLoading?: boolean }

/**
 * @deprecated use NextImage which uses the newer next Image component instead
 */
export function LEGACY_NextImage(props: Props) {
  const { isLoading, alt, src, ...rest } = props
  const [hasError, setHasError] = React.useState(false)
  React.useEffect(() => {
    setHasError(false)
  }, [src])

  // if the src coming through does not match any of our domains, we don't want next to do anything to it, so we use a
  // loader to just have it allow the src to go through to the img tag as it is when sent in
  const loaderProps = React.useMemo(() => {
    if (!src || typeof src !== 'string') return
    if (!imageDomains.some((x) => typeof src === 'string' && src.includes(x))) {
      return { loader: wildcardLoader }
    }
  }, [src])

  const shouldRender = src || !isLoading
  return (
    <>
      {shouldRender && (
        <>
          {hasError ? (
            <LazyImageFallback {...props} />
          ) : (
            <Image
              {...loaderProps}
              {...rest}
              // placing this separately to solve for a11y linting error
              alt={alt}
              // optimization should be done at the CDN level, not through next optimizing the image on our origin server
              unoptimized
              src={src}
              onError={(...args) => {
                if (typeof props.onError === 'function') props.onError(...args)
                setHasError(true)
              }}
            />
          )}
        </>
      )}
    </>
  )
}
