import { prodOrigin } from './prodOrigin'

export const cdnUrl = (fileSubdir: string) => `${prodOrigin}/cdn/${fileSubdir.replace(/^\/+/, '')}`
export const nonCloudfrontCdnUrl = (fileSubdir: string) =>
  `https://realize-prod-images.s3.dualstack.us-west-2.amazonaws.com/cdn/${fileSubdir.replace(/^\/+/, '')}`

// Data sources
export const ouraRingSrc = cdnUrl('/data+sources/oura-ring.webp')
export const fitbitSrc = cdnUrl('/data+sources/fitbit.webp')
export const garminSrc = cdnUrl('/data+sources/garmin.webp')
export const questLabsSrc = cdnUrl('/data+sources/quest-labs.webp')
export const appleHealthSrc = cdnUrl('/data+sources/apple-health.webp')
export const googleFitSrc = cdnUrl('/data+sources/google-fit.webp')
export const bridgeTrackerSrc = cdnUrl('/data+sources/bridge-tracker.webp')
export const pelotonSrc = cdnUrl('/data+sources/peloton.webp')
export const stravaSrc = cdnUrl('/data+sources/strava.webp')
export const withingsSrc = cdnUrl('/data+sources/withings.webp')
export const valdSrc = cdnUrl('/data+sources/vald+performance.webp')
export const whoopSrc = cdnUrl('/data+sources/whoop.webp')

// Logos

// General
export const avatarPlaceholderSrc = cdnUrl('/general/avatar-placeholder.webp')
export const circlesIntroductionBannerBackgroundSrc = cdnUrl('/general/circles-introduction-banner-background.webp')
export const realizeManFacingMountainSrc = cdnUrl('/general/background.webp')
export const ribbonSrc = cdnUrl('/general/ribbon.webp')
export const waterBottleEmptySrc = cdnUrl('/general/water-bottle-empty.webp')
export const waterBottleFullSrc = cdnUrl('/general/water-bottle-full.webp')
