import * as React from 'react'
import { atom, useRecoilState } from 'recoil'
import { Styles } from '../../utilities/getCssProp'
import { modalIds } from './modalIds'
import { DeviceMediaQueries } from '../../common/style/Display'

export type BooleanOrMediaQueries = boolean | DeviceMediaQueries

type JsxFunction = (p?: any) => JSX.Element

export type ModalOptions = Partial<{
  takeoverScreenExcludingAppHeader?: BooleanOrMediaQueries
  onClose?: () => void
  isClosable: boolean
  standardContainer?: boolean
  preserveModalStateOnClose: boolean
  overlayStyles?: Styles
  isFullscreenGradientTakeover?: boolean
  preventUnmountOnPathnameChange?: boolean
  modalId: modalIds | null
}>
type ModalState = { component: JsxFunction | null } & ModalOptions

const initialState: ModalState = {
  modalId: null,
  component: null,
  takeoverScreenExcludingAppHeader: false,
  isClosable: true,
  onClose: () => {},
  standardContainer: false,
  preserveModalStateOnClose: false,
  isFullscreenGradientTakeover: false,
  preventUnmountOnPathnameChange: false,
}

const modalState = atom({
  key: 'modalState',
  default: initialState,
})

type SetModalInput = JsxFunction | JSX.Element | undefined

export function useModalState() {
  const [state] = useRecoilState(modalState)
  const setModal = useSetModal()
  return [state, setModal] as const
}

export function useSetModal() {
  const [, recoilSetModal] = useRecoilState(modalState)

  const setModal = React.useCallback(
    (input?: SetModalInput | undefined, options?: ModalOptions) => {
      const component = typeof input == 'function' ? input : typeof input === 'object' ? () => input : null

      return recoilSetModal((s) => {
        return {
          ...s,
          ...options,
          modalId: options?.modalId || null,
          isClosable: typeof options?.isClosable === 'boolean' ? options.isClosable : true,
          preserveModalStateOnClose:
            typeof options?.preserveModalStateOnClose === 'boolean' ? options.preserveModalStateOnClose : false,
          component,
          isFullscreenGradientTakeover: !!(component && options?.isFullscreenGradientTakeover),
          preventUnmountOnPathnameChange: !!options?.preventUnmountOnPathnameChange,
        }
      })
    },
    [recoilSetModal]
  )

  return setModal
}

export function useActiveModal() {
  return useModalState()[0]?.component
}
