// Local Storage

export const supplementModalSelectionLocalStorageKey = 'supplementModalSelection'
export const lastManualDataSyncLocalStorageKey = 'lastManualDataSync'
export const currentVsAccountTzValueModalViewedLocalStorageKey = 'currentVsAccountTzValueModalViewed'
export const showCircleIntroductionInfoLocalStorageKey = 'showCircleIntroductionInfo'
export const lastViewedReportNotificationLocalStorageKey = 'lastViewedReportNotification'
export const kpiDateLocalStorageKey = 'kpiDate'
export const anonymousUserUnclaimedCartIdLocalStorageKey = 'anonymousUserUnclaimedCartId'

export const hideMeasurementsExplanationNotificationLocalStorageKey = 'hideMeasurementsExplanationNotification'
export const hideAddMembershipNotificationLocalStorageKey = 'hideAddMembershipNotification'
export const hidePwaNotificationLocalStorageKey = 'hidePwaNotification'
export const cardNotChargedUntilAppointmentLocalStorageKey = 'cardNotChargedUntilAppointment'

// PARTIAL KEYS
export const hidePartialLabResultsNotificationPartialLocalStorageKey = 'hideLabResultsNotification'
export const hideLabResultsNotificationPartialLocalStorageKey = 'hideLabResultsNotification'
export const hideLabResultNotificationPartialLocalStorageKey = 'hideLabResultNotification'
export const dataSourceInitialSyncStatusPartialLocalStorageKey = 'dataSourceInitialSyncStatus'
export const userHasReceivedLoggedItem1AchievementPartialLocalStorageKey = 'userHasReceivedLoggedItem1Achievement'
export const hideMetricOutOfDateNotificationPartialLocalStorageKey = 'hideMetricOutOfDateNotification'
export const selectedHomePageTilesPartialLocalStorageKey = 'selectedHomePageTiles'
export const makePartialStorageKey = (partialKey: string, identifier: string) => `${partialKey}-${identifier}`
