import React from 'react'
import { NextLink } from '../../../common/functionality/NextLink'
import { theme } from '../../../styles/theme'
import { NavigationArrow } from '../../../assets/components/NavigationArrow'

type Props = React.PropsWithChildren<{ href: string }>

export function LightGreenRoundLinkWithArrow({ href, children }: Props) {
  return (
    <NextLink
      className="mt-4 flex h-[52px] min-w-[128px] items-center justify-center gap-0.5 justify-self-center rounded-full bg-primary100 px-5 py-4 transition hover:bg-primary200"
      href={href}
    >
      <div className="text-base font-medium text-primary800">{children}</div>
      <NavigationArrow orientation="right" size={18} color={theme.colors.primary800} />
    </NextLink>
  )
}
