import React from 'react'
import { useAutoUpdatingRef } from '../../hooks/useAutoUpdatingRef'

export type Orientation = 'up' | 'down' | 'left' | 'right'

const getRotationAngle = (orientation?: Orientation) => {
  switch (orientation) {
    case 'up':
      return 180
    case 'right':
      return 270
    case 'down':
      return 0
    case 'left':
      return 90
    default:
      return 0
  }
}

export const useGetShortestRotationFromOrientation = (orientation?: Orientation) => {
  const [rotation, setRotation] = React.useState(() => getRotationAngle(orientation))

  const rotationRef = useAutoUpdatingRef(rotation)

  React.useEffect(() => {
    const targetRotation = getRotationAngle(orientation)
    const currentRotation = rotationRef.current % 360
    let rotateAmount = targetRotation - currentRotation

    // Ensure the rotation is the shortest distance
    if (Math.abs(rotateAmount) > 180) {
      rotateAmount += rotateAmount > 0 ? -360 : 360
    }

    setRotation(rotationRef.current + rotateAmount)
  }, [orientation, rotationRef])
  return rotation
}
